.projectWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  width: 90vw;
  margin-bottom: 3rem;
}

.projectImgWrapper {
  width: 40vw;
  height: 27vw;
  position: relative;
  transition: all 0.35s;
  cursor: pointer;

  img {
    width: 60%;
    position: absolute;
    transition: all 0.35s;
    border: 1px solid white;
    box-shadow: 5px 5px 10px #7140c9;
  }

  .topImg {
    top: 0;
    left: 0;
    margin: 1rem 0 0 1rem;
  }
  .bottomImg {
    bottom: 0;
    right: 0;
    margin: 0 1rem 1rem 0;
  }

  &:hover {
    & {
      box-shadow: none;
    }
    img {
      box-shadow: 5px 5px 20px #3fb950;
    }
    .topImg {
      left: 15%;
    }
    .bottomImg {
      right: 15%;
    }
  }
}

.projectTxt {
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .projectName {
    font-size: 5rem;
  }
  .projectTools {
    width: 70%;
    color: #3fb950;
    font-size: 1.3rem;
  }
  .projectDesc {
    width: 80%;
    color: #758d97;
    font-size: 1.5rem;
    margin-top: 2rem;
  }
}

.projectLinks {
  margin-top: 1.5rem;
  a {
    font-size: 1.5rem;
    margin-right: 2rem;
    color: #98acb4;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 480px) {
  .projectWrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .projectImgWrapper {
    width: 90vw;
    height: 60vw;
  }

  .projectTxt {
    width: 90vw;
    .projectName {
      font-size: 2.5rem;
    }
    .projectDesc,
    .projectTools {
      font-size: 1rem;
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 700px) {
  .projectWrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .projectImgWrapper {
    width: 70vw;
    height: 47vw;
  }

  .projectTxt {
    width: 70vw;
    .projectName {
      font-size: 2.5rem;
    }
    .projectDesc,
    .projectTools {
      font-size: 1rem;
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .projectTxt {
    .projectName {
      font-size: 3rem;
    }
    .projectDesc,
    .projectTools {
      font-size: 1.25rem;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .projectTxt {
    .projectName {
      font-size: 4rem;
    }
    .projectDesc,
    .projectTools {
      font-size: 1.25rem;
    }
  }
}
