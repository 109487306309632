@import "../../App.module.scss";

nav {
  box-shadow: 0px 0px 10px 5px #000000;
  position: sticky;
  top: 0;
  width: 100vw;
  z-index: 1000;
}

.hamburger {
  display: none;
}

.navContentWrapper {
  background-color: #0d1117;
  z-index: 100;
  transition: all 0.25s linear;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  align-items: center;

  ul {
    display: flex;
    list-style: none;
  }

  li {
    padding: 1rem;
    font-size: 2rem;
    cursor: pointer;

    a {
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 900px) {
  .hamburger {
    display: block;
    font-size: 2rem;
    padding: 0.5rem 1rem;
    transform: rotate(0deg);
    transition: all 0.25s;
  }

  .hamburger.active {
    transform: rotate(90deg);
  }

  nav {
    display: flex;
    justify-content: end;
    background-color: #12181f;
  }

  .navContentWrapper {
    position: absolute;
    flex-direction: column;
    left: 0;
    top: 0;
    background-color: #12181f;
    height: 100vh;
    padding: 1rem 3rem;
    transform: translateX(-100%);
    transition: all 0.25s linear;

    ul {
      flex-direction: column;
    }

    li {
      padding: 0.5rem;
      font-size: 1.5rem;
    }

    &.active {
      transform: translateX(0%);
    }
  }
}
