.heroSection {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 5vw;
  background-color: #0d1117;
}

.mobileBreak {
  display: none;
}

.heroTxt {
  width: 60vw;
  cursor: default;

  .heroTitle {
    font-size: 6rem;
    color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .heroSubtitle {
    font-size: 4rem;
    margin-bottom: 1rem;
    transition: all 0.35s;
    color: #758d97;
  }

  .heroDescription {
    margin-bottom: 2rem;
    color: #758d97;
  }
}

.heroImgWrapper {
  width: 25vw;
  height: 25vw;
  display: flex;
  justify-content: center;
  background-color: transparent;
  position: relative;

  .heroImg {
    transition: all 0.25s linear;
    box-shadow: inset 0 0 20px #fff, inset 20px 0 20px #3fb950,
      inset -20px 0 20px #7140c9, -10px 0 20px #3fb950, 10px 0px 20px #7140c9;
    border-radius: 50%;
    padding: 0.9rem;
    width: 100%;
    height: 100%;
  }

  .heroWalker {
    height: 50%;
    position: absolute;
    transform-origin: bottom;
    transition: all 3s linear;
    pointer-events: none;
    animation: rotateWalker 10s linear infinite;
  }

  .walker {
    transform: translate(0, -100%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: inset 0 0 20px #fff, inset 20px 0 20px #3fb950,
      inset -20px 0 20px #7140c9, -10px 0 20px #3fb950, 10px 0px 20px #7140c9;
    animation: changeWalkerColor 10s linear infinite;
  }
}

.projectsBtn {
  text-decoration: none;
  font-size: 1.5rem;
  color: white;
  padding: 1rem 2rem;
  box-shadow: inset 0px 0px 5px white;
  background-color: #12181f;
  border-radius: 25px;
  transition: all 0.25s;
}

.projectsBtn:hover {
  box-shadow: inset 0px 0px 10px white;
}

@media only screen and (max-width: 480px) {
  .mobileBreak {
    display: block;
  }
  .heroSection {
    flex-direction: column-reverse;
    height: auto;
    padding-top: 2rem;
  }

  .heroTxt {
    width: 100vw;
    padding: 0rem 1.5rem;

    .heroTitle {
      font-size: 3rem;
      width: 100%;
    }
    .heroSubtitle {
      font-size: 2rem;
    }
    .heroDescription {
      font-size: 1rem;
    }
  }

  .heroImgWrapper {
    width: 50vw;
    height: 50vw;
    margin-bottom: 2rem;
  }

  .projectsBtn {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 700px) {
  .heroSection {
    flex-direction: column-reverse;
    height: auto;
    padding-top: 2rem;
  }

  .heroTxt {
    width: 100vw;
    padding: 0rem 2rem;

    .heroTitle {
      font-size: 4rem;
    }
    .heroSubtitle {
      font-size: 2.5rem;
    }
    .heroDescription {
      font-size: 1.5rem;
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .heroTxt {
    .heroTitle {
      font-size: 4rem;
    }
    .heroSubtitle {
      font-size: 2.5rem;
    }
    .heroDescription {
      font-size: 1.5rem;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .heroTxt {
    .heroTitle {
      font-size: 5rem;
    }

    .heroSubtitle {
      font-size: 3rem;
    }
  }

  .projectsBtn {
    font-size: 1rem;
  }
}

@keyframes rotateWalker {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes changeWalkerColor {
  0% {
    box-shadow: inset 0 0 20px #fff, inset 20px 0 20px #7140c9,
      inset -20px 0 20px #7140c9, -10px 0 20px #7140c9, 10px 0px 20px #7140c9;
  }
  40% {
    box-shadow: inset 0 0 20px #fff, inset 20px 0 20px #7140c9,
      inset -20px 0 20px #7140c9, -10px 0 20px #7140c9, 10px 0px 20px #7140c9;
  }
  50% {
    box-shadow: inset 0 0 20px #fff, inset 20px 0 20px #3fb950,
      inset -20px 0 20px #3fb950, -10px 0 20px #3fb950, 10px 0px 20px #3fb950;
  }
  90% {
    box-shadow: inset 0 0 20px #fff, inset 20px 0 20px #3fb950,
      inset -20px 0 20px #3fb950, -10px 0 20px #3fb950, 10px 0px 20px #3fb950;
  }
  100% {
    box-shadow: inset 0 0 20px #fff, inset 20px 0 20px #7140c9,
      inset -20px 0 20px #7140c9, -10px 0 20px #7140c9, 10px 0px 20px #7140c9;
  }
}
