* {
  user-select: none;
}

body {
  background-color: #0d1117;
}

.sectionBreak {
  border: none;
  height: 15vh;
  width: 100vw;
  outline: none;
  &.skillsBreak {
    background-image: linear-gradient(to bottom, #0d1117, #12181f);
  }
}

.projectSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #12181f;
  padding-bottom: 20vh;
}

.sectionTitle {
  text-align: center;
  font-size: 12rem;
  margin: 3rem;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: #758d97;
  color: transparent;
}

.skillsSection {
  background-color: #12181f;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sectionTitle {
    margin: 0;
  }
}

.skillsWrapper {
  display: flex;
  width: 80vw;
  border-radius: 25px;
  overflow: hidden;
  justify-content: space-between;
  box-shadow: 0px 0px 20px black;
  padding: 2.5rem;
  margin: 2rem;
}

.contactSection {
  background-color: #0d1117;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 4rem;
}

.contactWrapper {
  width: 50vw;
  position: absolute;
  top: 0;
  background-color: #2f363e;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  padding: 4rem;
  text-align: center;

  .contactTxt {
    font-size: 2rem;
  }
}

.footerSection {
  background-color: #0d1117;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .iconSection {
    width: 40vw;
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
    border-bottom: 2px solid #2f363e;
    margin-bottom: 2rem;
  }

  .copyright {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 480px) {
  .skillsWrapper {
    display: block;
    width: 100vw;
    padding: 1rem;
  }
  .sectionTitle {
    font-size: 5rem;
  }

  .contactWrapper {
    padding: 1.5rem;
    width: 85vw;
    .contactTxt {
      font-size: 1rem;
    }
  }

  .contactSection {
    padding: 2rem;
  }

  .icon {
    font-size: 1rem;
    padding: 1rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 700px) {
  .sectionTitle {
    font-size: 5rem;
  }

  .contactWrapper {
    padding: 1.5rem;
    .contactTxt {
      font-size: 1rem;
    }
  }

  .contactSection {
    padding: 2rem;
  }

  .skillsWrapper {
    width: 95vw;
    padding: 1.5rem;
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .sectionTitle {
    font-size: 6rem;
  }

  .contactWrapper {
    padding: 2rem;
    .contactTxt {
      font-size: 1.25rem;
    }
  }
}

@media only screen and (min-width: 900) and (max-width: 1199px) {
  .sectionTitle {
    font-size: 7rem;
  }

  .contactWrapper {
    padding: 2rem;
    .contactTxt {
      font-size: 1.5rem;
    }
  }
}
